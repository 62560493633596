import apiClient from 'services/axiosApp'
import commonUtils from '../utils/common'
import { API_GATEWAY, PAYMENT_API } from '../constants/appConstants'
import { PROVIDER_TYPE_PAYMENT_LIST, TWO_C_TWO_P_TYPE } from 'constants/bookingConstants'
import { COUNTRY_TH, COUNTRY_ID } from 'constants/dashBoardAnalyticsConstants'
import I18n from 'i18n/i18n'
interface IParamPresigned {
  customerId: number
  countryCode: string
  amount: number
  transactionId: string
  companyId?: number
}
const PaymentIntegrationAPI = {
  getAddingFundSettings: (countryCode?: string) =>
    apiClient.get('/api/v3/top_up/settings', {
      params: {
        country_code: countryCode,
      },
      headers: {
        'App-Name': `${commonUtils.appName()} Webapp`,
        'Device-Type': 'Web',
      },
    }),
  getPresignedPayment: ({ customerId, countryCode, amount, transactionId, companyId }: IParamPresigned) => {
    const params = {
      action: 'payment',
      streamId: customerId,
      transactionId,
      content: {
        amount,
        remark: ['TH', 'th'].includes(countryCode) ? 'Payment to Deliveree' : 'Payment to 2C2P',
        ...(!!companyId && { companyId }),
      },
    }
    const country = countryCode ? countryCode.toLowerCase() : 'ph'
    let urlApiPresign = `${API_GATEWAY}/v1/payment-gateway/card/${country}/presign`
    if (PAYMENT_API) {
      urlApiPresign = `${PAYMENT_API}/api/v1/card/${country}/presign`
    }
    return apiClient.post(urlApiPresign, params)
  },
  getStatusPayment: ({
    customerId,
    countryCode,
    transactionId,
  }: {
    customerId: number
    countryCode: string
    transactionId: string
  }) => {
    const country = countryCode ? countryCode.toLowerCase() : 'ph'
    let urlApiStatusPayment = `${API_GATEWAY}/v1/payment-gateway/card/${country}/${customerId}/transaction/${transactionId}`
    if (PAYMENT_API) {
      urlApiStatusPayment = `${PAYMENT_API}/api/v1/card/${country}/${customerId}/transaction/${transactionId}`
    }
    return apiClient.get(urlApiStatusPayment)
  },
  removeFirebaseChannel: (channelPath: string) =>
    apiClient.delete(`${API_GATEWAY}/v1/notification`, {
      data: {
        xPath: channelPath,
        timeOut: null, // able null
      },
    }),
  preSignAPIPayment: (params: any, customerId: any, countryCode: string, type: string) => {
    const country =
      countryCode && countryCode.toLowerCase() !== 'vn'
        ? countryCode.toLowerCase()
        : type === PROVIDER_TYPE_PAYMENT_LIST[TWO_C_TWO_P_TYPE]
        ? 'ph'
        : 'id'
    const paymentType = type === PROVIDER_TYPE_PAYMENT_LIST[TWO_C_TWO_P_TYPE] ? 'card' : 'virtual-account'
    const url = `${API_GATEWAY}/v2/payment-gateway/${paymentType}/${country}/presign`

    return apiClient.post(url, params, {
      headers: {
        'x-owner': 'customer',
        'x-owner-id': customerId,
        'x-use-case': 'bookings',
      },
    })
  },
  getPaymentInstructionAPI: (params: any) =>
    apiClient.get(`${API_GATEWAY}/v3/payment-gateway/virtual-account/id/instructions`, { params }),
  createVAAPI: (preSignedData: { host: string; requestBody: any; headers: any }) =>
    apiClient.post(preSignedData.host, preSignedData.requestBody, {
      headers: preSignedData.headers,
    }),
  getPaymentSettingAPI: (countryCode: string, sectionType = '') => {
    const language = I18n.language
    const country = countryCode ? countryCode.toLowerCase() : 'id'
    const isTHAndID = COUNTRY_TH === countryCode || COUNTRY_ID === countryCode
    const acceptLang = isTHAndID && language === 'en' ? `en-${countryCode}` : language
    return apiClient.get(
      `${API_GATEWAY}/v1/payment-gateway/setting/customer?country=${country}&section_type=${sectionType}`,
      {
        headers: {
          Authorization: '',
          'Accept-Language': acceptLang,
        },
      }
    )
  },
  checkIsValidPaymentAPI: (id: any, params: any) =>
    apiClient.post(`${API_GATEWAY}/v1/payment-gateway/setting/${id}/available`, { ...params }),
  getItemPaymentSettings: (id: any, countryCode: string) => {
    const language = I18n.language
    const isTHAndPH = countryCode === 'TH' || countryCode === 'ID'
    const acceptLang = isTHAndPH && language === 'en' ? `en-${countryCode}` : language
    return apiClient.get(`${API_GATEWAY}/v1/payment-gateway/setting/${id}/customer`, {
      headers: {
        'Accept-Language': acceptLang,
      },
    })
  },
  getTippingPaymentSetting: (id: string, countryCode: string) => {
    const language = I18n.language
    const isTHAndPH = countryCode === 'TH' || countryCode === 'ID'
    const acceptLang = isTHAndPH && language === 'en' ? `en-${countryCode}` : language
    return apiClient.get(`${API_GATEWAY}/v1/payment-gateway/setting/${id}/tipping `, {
      headers: {
        'Accept-Language': acceptLang,
      },
    })
  },
  validateTippingPayment: (id: string, params: any) =>  {
    return apiClient.post(`${API_GATEWAY}/v1/payment-gateway/setting/${id}/tipping/verify`, params)
  }
}

export default PaymentIntegrationAPI
