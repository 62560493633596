// for ACTIONS -- BOOKING
export const BOOKING_UPDATE = 'BOOKING_UPDATE'
export const BOOKING_RETRY = 'BOOKING_RETRY'
export const RESET_BOOKING = 'RESET_BOOKING'
export const DATA_CHANGE = 'DATA_CHANGE'
// for TRACKING BOOKING
export const FIREBASE_SHOPPING_ORDER_UPDATE = 'SHOPPING_ORDER_UPDATE'
// TODO: it will be removed after s96
// export const FIREBASE_SHOPPING_CUSTOMER_UPDATE_SUCCESS = 'FIREBASE_SHOPPING_CUSTOMER_UPDATE_SUCCESS'
// export const FIREBASE_SHOPPING_CUSTOMER_UPDATE_FAILED = 'FIREBASE_SHOPPING_CUSTOMER_UPDATE_FAILED'
// export const FIREBASE_SHOPPING_CUSTOMER_REMOVE_SUCCESS = 'FIREBASE_SHOPPING_CUSTOMER_REMOVE_SUCCESS'
// export const FIREBASE_SHOPPING_CUSTOMER_REMOVE_FAILED = 'FIREBASE_SHOPPING_CUSTOMER_REMOVE_FAILED'
// export const FIREBASE_SHOPPING_CUSTOMER_ADD_SUCCESS = 'FIREBASE_SHOPPING_CUSTOMER_ADD_SUCCESS'
// export const FIREBASE_SHOPPING_CUSTOMER_ADD_FAILED = 'FIREBASE_SHOPPING_CUSTOMER_ADD_FAILED'

// for ACTIONS -- LOCATION
export const LOCATION_UPDATE_PARKING_CONFIRMED = 'LOCATION_UPDATE_PARKING_CONFIRMED'
export const LOCATION_UPDATE_TOLLS_CONFIRMED = 'LOCATION_UPDATE_TOLLS_CONFIRMED'
export const LOCATION_CONFIRM_REIMBURSEMENTS = 'LOCATION_CONFIRM_REIMBURSEMENTS'
export const SET_ACCEPTABLE_TIME_TYPES = 'SET_ACCEPTABLE_TIME_TYPES'

// for ACTIONS -- REIMBURSEMENT
export const REIMBURSEMENT_UPDATE_PARKING_CONFIRMED = 'REIMBURSEMENT_UPDATE_PARKING_CONFIRMED'
export const REIMBURSEMENT_UPDATE_TOLLS_CONFIRMED = 'REIMBURSEMENT_UPDATE_TOLLS_CONFIRMED'
export const CUSTOM_REIMBURSEMENT_UPDATE_CONFIRMED = 'CUSTOM_REIMBURSEMENT_UPDATE_CONFIRMED'
// for ACTIONS -- MULTIPLE BOOKINGS
export const ADD_BOOKING = 'ADD_BOOKING'
export const DELETE_BOOKING = 'DELETE_BOOKING'
export const UPDATE_BOOKING = 'UPDATE_BOOKING'
export const UPDATE_BOOKINGS = 'UPDATE_BOOKINGS'
// for Actions -- LTL
export const GET_HANDLING_UNIT_SUCCESS = 'GET_HANDLING_UNIT_SUCCESS'
export const GET_CONFIG_DATA_SUCCESS = 'GET_CONFIG_DATA_SUCCESS'
export const GET_SHIPMENT_LTL_DATA_SUCCESS = 'GET_SHIPMENT_LTL_DATA_SUCCESS'
export const UPDATE_STATUS_OUT_SERVICE_PARTIAL_LOAD = 'UPDATE_STATUS_OUT_SERVICE_PARTIAL_LOAD'

// for BOOKING STATUS
export const STATUS_LOCATING_DRIVER = 'locating_driver'
export const STATUS_LOCATING_DRIVER_TIMEOUT = 'locating_driver_timeout'
export const STATUS_DRIVER_ACCEPT_BOOKING = 'driver_accept_booking'
export const STATUS_FLEET_ACCEPT_BOOKING = 'fleet_accept_booking'
export const STATUS_DELIVERY_IN_PROGRESS = 'delivery_in_progress'
export const STATUS_DELIVERY_COMPLETED = 'delivery_completed'
export const STATUS_UNDELIVERABLE = 'undeliverable'
export const STATUS_CANCELLED = 'canceled'
export const STATUS_CS_FINDING_DRIVER = 'cs_finding_driver'
export const STATUS_WAITING_FOR_CUSTOMER = 'waiting_for_customer'
export const STATUS_CANCELED_REBOOK = 'canceled_rebook'
export const STATUS_FLEET_TIMEOUT = 'fleet_timeout'
export const STATUS_SAVE_AS_DRAFT = 'draft_pending'
export const STATUS_ASSIGNING_DRIVER = 'assigning_driver'
export const STATUS_ASSIGNING_DRIVER_TIMEOUT = 'assigning_driver_timeout'
export const STATUS_DRIVER_DECLINED_BOOKING = 'driver_declined_booking'
export const STATUS_NEED_RECOVERY = 'recovering'
export const STATUS_TO_AT_PICKUP = 'to_at_pickup'
export const STATUS_TO_AT_DESTINATION = 'to_at_destination'
export const STATUS_AT_PICKUP = 'at_pickup'
export const STATUS_AT_DESTINATION = 'at_destination'
export const STATUS_GOING_TO_DESTINATION = 'going_to_destination'
export const STATUS_DRIVER_ON_THE_WAY = 'driver_on_the_way'
export const STATUS_CONFIRMED = 'confirmed'
export const STATUS_REIMBURSE = 'reimburse'
export const STATUS_CANCEL_TO_EDIT = 'cancel_to_edit'
export const TIME_OUT_STATUS_LIST = [STATUS_ASSIGNING_DRIVER_TIMEOUT, STATUS_LOCATING_DRIVER_TIMEOUT]

// for BOOKING RECOVERY STATUS
export const RECOVERY_STATUS_REQUEST_RECOVERY = 'request_recovery'
export const RECOVERY_STATUS_DECLINE_RECOVERY = 'decline_recovery'

// for BOOKING TIME TYPE
export const NOW = 'now'
export const IMMEDIATE = 'immediate'
export const SCHEDULE = 'schedule'
export const FULL_DAY = 'full_day'
export const QUICK_CHOICE = 'quick_choice'
export const LONG_HAUL = 'long_haul'
export const PICKUP = 'pickup'
export const DESTINATION = 'destination'
export const DROPOFF = 'dropoff'
export const FULL_DAY_AMOUNT_DEFAULT = 1
export const DEFAULT_ACCEPTABLE_TIME_TYPES = [NOW, IMMEDIATE, SCHEDULE, FULL_DAY, LONG_HAUL]

export const SCHEDULE_VALUE = 2
export const FULL_DAY_VALUE = 3
export const LONG_HAUL_VALUE = 4

// TIME TYPE OBJECT
export const SCHEDULE_TIME_TYPE = {
  display_time_type_key: SCHEDULE,
  display_time_type_value: SCHEDULE_VALUE,
  type_key: SCHEDULE,
  type_value: SCHEDULE_VALUE
}

// for SETTING VALUES
export const MAXIMUM_BOOKING_COUNT = 10
export const ONE_MIN_TO_MS = 60000
export const ONE_HOUR_TO_MS = 3600000
export const TEN_SECONDS = 1000
export const ONE_HOUR_TO_MIN = 60
export const PHOTO_ATTACHMENT_NUMBER = 3
export const MAXIMUM_BOOKING_NOTE_LENGTH = 1000
export const MIN_PHONE_INPUT_COUNT = 1
export const MAX_LENGTH_NOTE = 255
// for BOOKING CREATED BY
export const WEB_EZ_SHEET = 'web_batch'
export const WEB_MULTIPLE = 'web_multiple'
export const WEB_SINGLE = 'web_single'
export const WEB_PLANNER = 'web_planner'

// Long Haul
export const UPDATE_DROP_OFF_ZONE = 'UPDATE_DROP_OFF_ZONE'
export const UPDATE_GROUP_DROP_OFF_ZONE = 'UPDATE_GROUP_DROP_OFF_ZONE'
export const POLYGON_SETTINGS = {
  strokeColor: '#3FAE29',
  strokeOpacity: 0.6,
  strokeWeight: 1,
  fillColor: '#3FAE29',
  fillOpacity: 0.4,
  fillOpacityAdjusment: 0.1,
}
export const UPDATE_PICKUP_ZONE = 'UPDATE_PICKUP_ZONE'
export const UPDATE_GROUP_PICKUP_ZONE = 'UPDATE_GROUP_PICKUP_ZONE'
export const UPDATE_LONG_HAUL_ZONES = 'UPDATE_LONG_HAUL_ZONES'
export const UPDATE_POLYGON_ZONES = 'UPDATE_POLYGON_ZONES'
export const BOOKING_UPDATE_DRIVER_STATUS = 'BOOKING_UPDATE_DRIVER_STATUS'
export const UPDATE_SELECTED_PICKUP_ZONE = 'UPDATE_SELECTED_PICKUP_ZONE'
export const UPDATE_LONG_HAUL_PRICING = 'UPDATE_LONG_HAUL_PRICING'
export const UPDATE_SELECTED_DROP_OFF_ZONE = 'UPDATE_SELECTED_DROP_OFF_ZONE'
export const RESET_SELECTED_PICKUP_ZONE = 'RESET_SELECTED_PICKUP_ZONE'
export const ADD_CUSTOM_DROP_OFF_ZONE = 'ADD_CUSTOM_DROP_OFF_ZONE'
export const ALLOW_WAITING_TIME_FEES = 'allow_waiting_time_fees'
export const ALLOW_PARKING_FEES = 'allow_parking_fees'
export const ALLOW_TOLLS_FEES = 'allow_tolls_fees'
export const NEW_GEN_POD = 'new_gen_pod'
export const CPOD = 'cpod'
export const UPDATE_DOCUMENT_RETURN = 'UPDATE_DOCUMENT_RETURN'
export const RESET_DOCUMENT_RETURN = 'RESET_DOCUMENT_RETURN'
export const WAITING_TIME_FEES = 'waiting_time_fees'
export const PARKING_FEES = 'parking_fees'
export const TOLLS_FEES = 'tolls_fees'
// for Saved Booking
export const MULTIPLE_SAVED_BOOKING_PERPAGE = 5
export const RECEIVE_TOTAL_COUNT_PAGINATION = 'receive_total_count'
export const RECEIVE_PERPAGE_NUMBER = 'receive_perpage_number'
export const RECEIVE_PAGINATE_VALUES = 'receive_paginate_values'
// for Booking scope
export const BOOKING_SCOPE_FAVORITED = 'favorited'
// for Booking round trip discount
export const UPDATE_ROUND_TRIP_DISCOUNT = 'UPDATE_ROUND_TRIP_DISCOUNT'
// for Dynamic texts
export const SET_DYNAMIC_TEXTS = 'SET_DYNAMIC_TEXTS'
// for Show route
export const UPDATE_SHOW_ROUTE_STATE = 'UPDATE_SHOW_ROUTE_STATE'
export const UPDATE_POLYLINE_STATE = 'UPDATE_POLYLINE_STATE'
export const UPDATE_PREV_ROUTE_STATE = 'UPDATE_PREV_ROUTE_STATE'
export const UPDATE_PREV_LOCATIONS_STATE = 'UPDATE_PREV_LOCATIONS_STATE'
export const UPDATE_PREV_OPTIMIZE_ROUTE_STATE = 'UPDATE_PREV_OPTIMIZE_ROUTE_STATE'
export const UPDATE_WAYPOINT_ORDER_STATE = 'UPDATE_WAYPOINT_ORDER_STATE'
// for Customer payment status
export const CUSTOMER_PAID = 'customer_paid'
export const PAYMENT_PENDING = 'payment_pending'
export const SETTLEMENT_REJECTED = 'rejected'

// buffer time for booking
export const BUFFER_TIME_BOOKING = 5 // Minutes
export const UPDATE_REQUIRE_SIGNATURES = 'UPDATE_REQUIRE_SIGNATURES'

// for Tally
export const BOOKING_UPDATE_TALLY = 'BOOKING_UPDATE_TALLY'
export const BOOKING_UPDATE_CASHBACK_AMOUNT = 'BOOKING_UPDATE_CASHBACK_AMOUNT'

// for tally status
export const TALLY_WAITING_FOR_PICKUP = 'waiting_for_pickup'
export const TALLY_ON_SCHEDULE = 'on_schedule'
export const TALLY_EARLY = 'early'
export const TALLY_DELAY = 'late'
export const TALLY_NOW = 'now'
export const TALLY_REMAINING = 'remaining'
export const TALLY_OVERTIME = 'over_time'
export const TALLY_LIST_STATUS_ACCEPT = [
  STATUS_DRIVER_ACCEPT_BOOKING,
  STATUS_FLEET_ACCEPT_BOOKING,
  STATUS_DELIVERY_IN_PROGRESS,
]

export const FRESH_CHAT_CHANNEL = 'Chat with Us'
export const FRESH_CHAT_CHANNEL_PH = 'Booking Recovery'
export const FRESH_CHAT_CHANNEL_TH = 'Welcome to the Revolution'

// for CPOD
export const BOOKING_UPDATE_COD_FEE = 'BOOKING_UPDATE_COD_FEE'

// for LTL Booking Status
export const SHIPMENT_STATUS = {
  DRAFT: 1,
  WAITING_APPROVAL: 2,
  APPROVED: 3,
  HOLD: 4,
  BLOCKED: 5,
  UNLISTED: 6,
  BOOKED: 7,
  INPROGRESS: 8,
  COMPLETED: 9,
  CANCELLED: 10,
}
// For driver preference popup
export const FAV_DRIVER_ID = 'fav_driver_id'
// For custome price
export const CUSTOM_PRICE = 'customized_total_price'

export const UPDATE_REQUIREMENT_NOTMET_REASON = 'UPDATE_REQUIREMENT_NOTMET_REASON'
export const RECEIVE_FAVORITE_DRIVERS = 'RECEIVE_FAVORITE_DRIVERS'
export const SUB_ACCOUNT_TAG_PICKED = 'SUB_ACCOUNT_TAG_PICKED'
export const UPDATE_REQUIRE_SUB_ACCOUNT = 'UPDATE_REQUIRE_SUB_ACCOUNT'
export const SUB_ACCOUNT_TAG_LIST = 'SUB_ACCOUNT_TAG_LIST'

export const UPDATE_POPUP_ID_BOOKING = 'UPDATE_POPUP_ID_BOOKING'
export const SIGNIN_PROGRESS_FINISHED = 'SIGNIN_PROGRESS_FINISHED'
export const UPDATE_OUTSIDE_LIST = 'UPDATE_OUTSIDE_LIST'

export const PRIORITY_BUSINESS = 'priority_business'
export const PAY_BY_CASH = 'pay_by_cash'
export const PAY_BY_CREDIT = 'pay_by_credit'
export const PAID_BY_CREDIT = 'paid_by_credit'
export const PAID_BY_CREDIT_AND_CASH = 'paid_by_credit_and_cash'
export const PAY_CASH = 'Cash'
export const PAYMENT_METHOD_CONVERT_LIST = {
  cash: 'Cash',
  virtualAccount: 'Virtual Account',
  card: 'Online Payment',
}
export const BATCH_INIT = {
  area_id: null,
  batch_template_id: null,
  batch_type: null,
  bookings: [],
  bookings_count: 0,
  by_option_extra: null,
  company_id: null,
  contact_id: null,
  created_at: null,
  created_by: null,
  customer_id: null,
  deleted_at: null,
  desc: '',
  file_name: '',
  id: null,
  marked_as_favorite_batch: false,
  name: '',
  need_confirmation: false,
  pickup_time: null,
  pickup_time_mode: null,
  planner_id: null,
  status: 'arranging',
  step: 'multiple_1',
  updated_at: null,
  vehicle_type_id: null,
}
export const LOCATION_STATUSES = {
  FAILED: 'failed',
  DELIVERED: 'delivered',
}
export const iconTextPositions = [27, 24]
export const labelPositions = { x: 34, y: 32 }
export const labelDropDrapPositions = { x: 94, y: 73 }

export const iconTextColors = {
  white: 'white',
  black: 'black',
  gray: '#282828',
}

export const GET_BOOKING_SUCCESS = 'booking/GET_BOOKING_SUCCESS'
export const UPDATE_LOCATION_SUCCESS = 'booking/UPDATE_LOCATION_SUCCESS'
export const UPDATE_DEVINA_SUCCESS = 'booking/UPDATE_DEVINA_SUCCESS'

export const TIME_TYPE_TEXT = {
  [NOW]: 'Immediate',
  [IMMEDIATE]: 'Immediate',
  [SCHEDULE]: 'Schedule',
  [LONG_HAUL]: 'Fixed Price Route',
  [FULL_DAY]: 'Full Day',
}

// For payment method
const PRESIGNED_TIMEOUT = 'presigned_timeout'
const VIRTUAL_ACCOUNT_TIMEOUT = 'virtual_account_timeout'
const PAYMENT_FAILED = 'failed'

export const FAILED_PAYMENT_STATUS = [PRESIGNED_TIMEOUT, VIRTUAL_ACCOUNT_TIMEOUT, PAYMENT_FAILED]
export const BOOKING_FAILED_STATUS = [
  STATUS_CANCELLED, RECOVERY_STATUS_REQUEST_RECOVERY, STATUS_CANCEL_TO_EDIT,
  STATUS_NEED_RECOVERY, STATUS_UNDELIVERABLE, STATUS_CANCELED_REBOOK,
]

export const PAYMENT_VA_STATUS = {
  initiated: 'initiated',
  presigned: 'presigned',
  presignedTimeout: PRESIGNED_TIMEOUT,
  virtualAccountCreated: 'virtual_account_created',
  virtualAccountTimeout: VIRTUAL_ACCOUNT_TIMEOUT,
  paymentFailed: PAYMENT_FAILED,
  paid: 'paid',
}

export const PROCESSING_PAYMENT_LIST = [PAYMENT_VA_STATUS.initiated, PAYMENT_VA_STATUS.presigned]

export const VALID_PAYMENT_LIST = [
  PAYMENT_VA_STATUS.initiated, PAYMENT_VA_STATUS.presigned, PAYMENT_VA_STATUS.virtualAccountCreated
]

export const PRICING_PAYMENT_INCREASE = 'increase'
export const PRICING_PAYMENT_CHANGED = [PRICING_PAYMENT_INCREASE, 'decrease']
export const SHOW_PAYMENT_METHOD_LIST = [
  'pay_by_credit_and_online_payment',
  'pay_by_online_payment',
  'pay_by_cash',
  'paid_by_credit_and_cash',
  'pay_by_credit_and_online_payment_2c2p',
  'pay_by_online_payment_2c2p',
]

export const EXPIRED_VA_ID = 'expired_va_id'
export const EXPIRED_EDIT_VA_ID = 'expired_edit_va_id'
export const IS_CEB_VA_PAYMENT = 'is_ceb_va_payment'
export const PAID_BY_VA = 'paid_by_va'

export const IS_DRIVER_NOT_AVAILABLE = 'is_driver_not_available'


// Section type
export const CUSTOMER_PAYMENT = 1
export const CUSTOMER_QUOTE_PAYMENT = 2
export const CUSTOMER_PAYMENT_STATUS = 3
export const CUSTOMER_SPECIAL_SETTINGS = 4
export const SPECIAL_SETTINGS_AREA = 5
export const BOOKING_DETAILS = 6
export const CUSTOMER_QUOTE_TIPPING_PAYMENT = 7

export const SECTION_TYPE_PAYMENT_LIST = {
  [CUSTOMER_PAYMENT]: 'customer_payment',
  [CUSTOMER_QUOTE_PAYMENT]: 'customer_quote_payment',
  [CUSTOMER_PAYMENT_STATUS]: 'customer_payment_status',
  [CUSTOMER_SPECIAL_SETTINGS]: 'customer_special_settings',
  [SPECIAL_SETTINGS_AREA]: 'area_special_settings',
  [BOOKING_DETAILS]: 'booking_detail',
  [CUSTOMER_QUOTE_TIPPING_PAYMENT]: 'customer_quote_tipping_payment',
}

export const SECTION_TYPE_BOOKING_INFO = {
  CUSTOMER_LIVE_TRACKING: 'customer_live_tracking',
}

// Provider type
export const SPIL_TYPE = 1
export const TWO_C_TWO_P_TYPE = 2

export const PROVIDER_TYPE_PAYMENT_LIST = {
  [SPIL_TYPE]: 'spil',
  [TWO_C_TWO_P_TYPE]: '2c2p',
}

export const METHOD_CASH = 'cash'
// For payment method end

// Section type booking details
export const CUSTOMER_PRICE_SUMMARY = 1
export const SECTION_TYPE_BOOKING_DETAIL = {
  [CUSTOMER_PRICE_SUMMARY]: 'customer_details_pricing_summary',
}

export const MANAGE_BANNED = 'manage_banned'

export const LCL_STATUS_COMPLETE = 10
export const LCL_STATUS_GOOD_ACCEPT = 6
export const LCL_STATUS_AT_DESTINATION = 8
export const LCL_STATUS_INLAND = 9
export const LCL_STATUS_STRIPPING = 13

export const TAB_DRIVER_ACTIVE = 'Driver'
export const LTL_DISABLED_BTN_CLASS = 'ltl-disabled-btn'
export const DRIVER_IMAGE_LIGHT_BOX_CLASS = 'driver-light-box-class'
