// UTILS
import commonUtils from '../../../utils/common'
// API
import CustomerAPI from 'api/customers'
import SettingAPI from 'api/settings'
import _ from 'lodash'
// ACTIONS
// COMPONENTS
// CONSTANTS
import { LONG_HAUL } from 'constants/bookingConstants'
import { isEditBooking, setAttachments, getParamFromURL } from 'utils/booking/common'
import { attachmentsActionsCreator } from 'store/toolkit/newBooking/attachments.reducer'
import { isLoginStep3Creator } from 'store/toolkit/newBooking/isLoginStep3.reducer'
import { currentCustomerActionsCreator } from 'store/toolkit/currentCustomer/currentCustomer.reducer'
import { extraInfosActionsCreator } from 'store/toolkit/extraInfos/extraInfos.reducer'
import { requireSignaturesActionsCreator } from 'store/toolkit/requireSignatures/requireSignatures.reducer'
import * as bookingsActionCreators from 'store/actions/multiple_bookings/bookingsActionCreators'
import { batchActionsCreator } from 'store/toolkit/batch/batch.reducer'
import * as bookingActionCreators from 'store/actions/common/bookingActionCreators'
import * as areaActionCreators from 'store/actions/common/areaActionCreators'
import { parseJwt, setAccessToken } from 'utils/crossStorage'
import store from 'store/store'
import currentLocationUtils from 'utils/common/getCurrentLocation'

export const updateCustomer = (customer) => currentCustomerActionsCreator.updateCustomer(customer)

export const updateCurrentCustomer = (authenticationToken, params, extraUpdate) => async (dispatch) => {
  const infoUser = await CustomerAPI.getCustomer(authenticationToken, params)
  if (infoUser.statusApi === 401) setAccessToken('')
  dispatch(updateCustomer({ ...infoUser, ...extraUpdate }))
}

export const joinCustomerChat = (bookingID, authenticationToken, callback) => () => {
  CustomerAPI.postJoinCustomerChat(bookingID, authenticationToken, (response) => {
    callback(response)
  })
}

export const handleSetIsLoginStep3 = (isLoginStep3) => (dispatch) =>
  dispatch(isLoginStep3Creator.setIsLoginStep3(isLoginStep3))

const actionDispatchByPage = (responseCashBack, enoughDataCheckCashBack, data, bookings, dispatch, batch) => {
  const cashBackReward = {
    cash_back_reward: responseCashBack,
    enough_data_check_cashback: enoughDataCheckCashBack,
  }
  if (commonUtils.isMultiple()) {
    dispatch(bookingsActionCreators.updateBooking(bookings[data.idx]?.id, cashBackReward))
    return
  }
  if (commonUtils.isBatchEZ() || commonUtils.isSmartLoad()) {
    const bookingsChange = batch.bookings[data.idx]
    dispatch(
      batchActionsCreator.updateBooking({
        booking: {
          temp_id: bookingsChange?.temp_id,
          cash_back_reward: responseCashBack,
          enough_data_check_cashback: enoughDataCheckCashBack,
        },
      })
    )
    return
  }
  dispatch(bookingActionCreators.updateBookingAttributes(cashBackReward))
}
export const calculateCustomerCashbackPercent =
  (data, isHaveLHAddress = false) =>
  async (dispatch, getState) => {
    const { currentCustomer, extraInfos, bookings, batch } = getState()
    const isCompany = currentCustomer.current_company_id
    const filteredLocations = data?.locations_attributes[0]
    const result = []
    if (filteredLocations) {
      result.push({
        latitude: filteredLocations.lat,
        longitude: filteredLocations.lng,
        name: filteredLocations.name,
      })
    }
    const params = {
      country_code: extraInfos.country_code,
      company_id: currentCustomer.current_company_id || currentCustomer.id,
      account_type: isCompany ? 'company' : 'customer',
      locations_attributes: result,
      time_type: isHaveLHAddress ? LONG_HAUL : data.time_type,
      vehicle_type_id: data.vehicle_type_id,
    }
    if (params.locations_attributes[0]?.latitude && params.time_type && params.vehicle_type_id) {
      const { data: cashbackData } = await CustomerAPI.postCalculateCashbackPercent(params)
      const responseCashBack = cashbackData.cashback_credit_earn || null
      actionDispatchByPage(responseCashBack, true, data, bookings, dispatch, batch)
    } else {
      actionDispatchByPage(null, false, data, bookings, dispatch, batch)
    }
  }

// Helper function getCustomerCreditAmount
const executeCallback = (callback) => {
  if (typeof callback === 'function') {
    callback()
  }
}

const processWalletData = ({ resWallet, isBpPostPaid }) => {
  const creditWallet = resWallet?.data?.credit_wallet
  const cashbackWallet = resWallet?.data?.cashback_wallet

  if (_.isEmpty(creditWallet)) return null

  if (isBpPostPaid) {
    return {
      ...cashbackWallet,
      balance: creditWallet.amount,
    }
  }

  return creditWallet
}
// End Helper function getCustomerCreditAmount

export const getCustomerCreditAmount =
  (acceptEmptyCredit = false, callback) =>
  async (dispatch, getState) => {
    const { currentCustomer, extraInfos } = getState()
    const companyId = currentCustomer.current_company_id
    const id = companyId || currentCustomer.id

    if (!id) {
      executeCallback(callback)
      return
    }

    const params = {
      id,
      country_code: extraInfos.country_code,
      account_type: companyId ? 'company' : 'customer',
    }
    const resWallet = await CustomerAPI.getCreditBalance(params)

    if (resWallet.status === 200) {
      const credit = processWalletData({ resWallet, isBpPostPaid: currentCustomer?.allow_post_payment })
      if (credit) {
        credit.acceptEmptyCredit = acceptEmptyCredit
        dispatch(updateCustomer({ credit }))
        executeCallback(callback)
      }
    }
  }

// Helper function getCustomerSettings
const handleApiResponse = (dispatch, data, currentCustomer, attachmentsStore, finalAreaId) => {
  const dynamicTexts = data.dynamic_text_json
  const extraInfosUpdate = {
    ...data,
    dynamicTexts,
    area_id: finalAreaId,
    id: finalAreaId,
    address_components: data.address_component_for_displaying || [],
    enable_address_components: data.enable_display_address_component_configurations,
  }

  if (!isEditBooking()) {
    dispatch(requireSignaturesActionsCreator.updateRequireSignatures(data.booking_previous_require_signatures))
  }

  if (currentCustomer.id) {
    dispatch(updateCustomer({
      booking_attachments: data.booking_attachments,
      send_sms_to_recipients: data.send_sms_to_recipients,
      show_phone_number_to_driver: data.show_phone_number_to_driver,
      ceb_enabled_feature: data.ceb_enabled_feature,
      cs_working_hours: data.cs_working_hours,
      update_booking_interval: data.update_booking_interval,
      allow_post_payment: data.allow_post_payment,
    }))
  }

  dispatch(extraInfosActionsCreator.updateExtraInfos(extraInfosUpdate))

  handleAttachments(dispatch, currentCustomer, data.booking_attachments, attachmentsStore)
}

const handleAttachments = (dispatch, currentCustomer, bookingAttachments, attachmentsStore) => {
  if (commonUtils.isSingle() && _.isEmpty(attachmentsStore)) {
    let attachments = []
    if (currentCustomer.id && bookingAttachments) {
      attachments = setAttachments(bookingAttachments)
    } else {
      attachments = setAttachments([])
    }
    dispatch(attachmentsActionsCreator.updateAttachments(attachments))
  }
}
//End Helper function getCustomerSettings

export const getCustomerSettings = (accessToken, areaId) => (dispatch, getState) => {
  const { currentCustomer, extraInfos, attachments: attachmentsStore } = getState()

  const finalCountryCode = extraInfos.country_code
  const finalAreaId = areaId || extraInfos.area_id

  if (!finalCountryCode || !finalAreaId) return

  const query = {
    company_id: currentCustomer.current_company_id || 0,
    include: [
      'send_sms_to_recipients',
      'show_phone_number_to_driver',
      'default_note',
      'booking_attachments',
      'ceb_enabled_feature',
      'update_booking_interval',
    ],
    area_id: finalAreaId,
    include_fleet_driver: true,
  }
  const token = accessToken || window.localStorage.getItem('access_token') || ''

  SettingAPI.getCustomerSettingsApi(
    token,
    query,
    (response) => {
      if (response?.data) {
        handleApiResponse(dispatch, response.data, currentCustomer, attachmentsStore, finalAreaId)
      }
    },
    finalCountryCode
  )
}

export const getCurrentCustomerProps = async (batchType) => {
  const {
    currentCustomer,
    currentArea: { id },
  } = store.getState()
  const dispatch = store.dispatch
  const areaId = window.localStorage.getItem('areaId')
  const params = {
    batch_type: batchType,
    area_id: id || areaId,
    ...(currentCustomer.last_login_employ_id && { company_id: currentCustomer.last_login_employ_id }),
  }
  const { status, data } = await CustomerAPI.getCurrentCustomerProps(params)
  if (status === 200) {
    const customer = { ...data }
    if (!data.authentication_token) {
      delete customer.authentication_token
    }

    if (currentCustomer.id) {
      delete customer.id
    }

    dispatch(currentCustomerActionsCreator.updateCustomer(data))
  }
}

// Helper function getCurrentCustomer
const handleApiResponseCurrentCustomer = async (res, authenticationToken, dispatch, finalAreaId) => {
  if (res?.statusApi === 401) {
    setAccessToken('')
    window.location.reload()
  } else if (res?.statusApi === 500) {
    const countryCode = getParamFromURL('country_code')
    dispatch(areaActionCreators.getCurrentAreaCustomer(countryCode))
  } else if (!_.isEmpty(res)) {
    //handle for case token jwt and token last_login different need to convert new token or logout
    await handleValidResponse(res, authenticationToken, dispatch, finalAreaId)
  }
}

const handleValidResponse = async (res, authenticationToken, dispatch, finalAreaId) => {
  let locationOfCustomer

  const decodeToken = parseJwt(authenticationToken)
  const companyIdFromToken = +decodeToken?.company_id || 0
  const companyIdNow = +res?.last_login_employ_id || 0

  if (authenticationToken && companyIdFromToken !== companyIdNow) {
    commonUtils.handleSignOut(() => {
      window.location.reload()
    })
  }
  const locationCurrent = await getCurrentLocation()

  if (locationCurrent?.currLat || locationCurrent?.currLong) {
    locationOfCustomer = { latitude: locationCurrent?.currLat, longitude: locationCurrent?.currLong }
  }

  dispatch(
    updateCustomer({
      ...res,
      authentication_token: authenticationToken,
      area_id: finalAreaId,
      current_company_id: res.last_login_employ_id || 0,
      location_of_customer: locationOfCustomer || {},
    })
  )
}

const getCurrentLocation = async () => {
  try {
    return await Promise.race([
      currentLocationUtils.getCurrentLocationOfDevice(),
      new Promise((_, reject) => setTimeout(() => reject(new Error('Request timed out')), 500)),
    ])
  } catch (error) {
    console.log(error)
  }
}
//End Helper function getCurrentCustomer

export const getCurrentCustomer =
  (authenticationToken, finalAreaId, lastLoginEmployId) => async (dispatch, getState) => {
    if (!finalAreaId || !authenticationToken) return

    const params = {
      area_id: finalAreaId,
      include: [
        'companies',
        'company',
        'employs',
        'send_sms_to_recipients',
        'show_phone_number_to_driver',
        'has_bookings', // use for My Bookings
      ],
      company_id: lastLoginEmployId,
    }
    const res = await CustomerAPI.getCustomer(authenticationToken, params)

    handleApiResponseCurrentCustomer(res, authenticationToken, dispatch, finalAreaId)
  }
