import React from 'react'
import { Utils } from './Utils'
import commonUtils from './common'
import _ from 'lodash'
import {
  CUSTOM_PRICE,
  FULL_DAY, LONG_HAUL, SETTLEMENT_REJECTED
} from 'constants/bookingConstants'
import { EXTRA_REQUIREMENT_VEHICLE_TYPE_BY_OPTIONS } from 'constants/extraServiceConstants'
import {
  FIXED_PRICE_ICON_LONG_HAUL,
  FREE_INSIDE_ICON_LONG_HAUL,
  PRICE_OUTSIDE_ICON_LONG_HAUL,
  ICON_CANCEL_FEE,
  ICON_NOTE_CUSTOM_PRICE,
} from 'constants/imageConstants'
import DynamicPopupHandler from 'components/common/DynamicPopupHandler'
import ToolTipsSubAccount from 'components/new_booking/guest_flow/ToolTipsSubAccount'
import { renderHyperLink, renderFee } from './booking/common'
import I18n from 'i18n/i18n'
import HelpTooltip from 'components/common/tooltip/HelpTooltip'
import store from 'store/store'

const checkIsShowTooltipPlatformFee = (extraRequirement, dynamicTextPlatformFee) => {
  const isHideStep2 = extraRequirement.hide_from_services_selection
  const isPlatformFee = ['Platform Fee', 'Booking Fee', 'Biaya Jasa Aplikasi', 'ค่าธรรมเนียมการจองงาน'].includes(extraRequirement.name?.trim())
  return isHideStep2 && isPlatformFee && dynamicTextPlatformFee?.text
}

const ReceiptUtils = {
  renderSubItem(label, price, countryLanguage, key = undefined, bookingType, demandContent = { hyperlink_url: '', text: '', hyperlink_text: '' }) {
    const dontShowIfEmptyKeys = [
      'out_of_service_area_fee',
      'way_point_fees',
      'after_hour_service_fees',
      'surcharges_fees',
      'discount_amount',
      'credit_amount',
      'company_type_discount',
      'business_credit_amount'
    ]
    const boldKeys = [
      'discount_amount',
      'credit_amount',
      'way_point_fees'
    ]
    const negativeKeys = [
      'discount_amount',
      'credit_amount',
      'company_type_discount',
      'business_credit_amount'
    ]
    const nonZeroKeys = [
      'surcharges_fees'
    ]
    let displayPrice
    if (bookingType === CUSTOM_PRICE) {
      displayPrice = I18n.t('webapp.pricing_display.included')
    } else {
      displayPrice = (_.includes(negativeKeys, key) ? `-${Utils.formatFee(price, countryLanguage)}` : Utils.formatFee(price, countryLanguage))
    }
    const isBold = _.includes(boldKeys, key)
    const checkingPrice = _.includes(nonZeroKeys, key) ? (price === 0) : (price <= 0)
    if (_.includes(dontShowIfEmptyKeys, key) && checkingPrice) {
      return null
    }
    const isRenderIconDemand = label === I18n.t('webapp.pricing_display.surcharges_fees') && demandContent.text
    const content = isRenderIconDemand
    && renderHyperLink(demandContent.hyperlink_url, demandContent.text, demandContent.hyperlink_text, 'hyperlink-color-tooltip')
    return (
      <div className={`${isBold ? 'block-item block-item-custom' : 'block-sub-item'} ${isRenderIconDemand && 'z-index-22-max'}`} key={key}>
        <label className={isRenderIconDemand && 'flex'}>
          {isBold
            ? (
              <b>
                {label}
              </b>
            )
            : (
              <span>
                {label}
              </span>
            )
          }
          {isRenderIconDemand && (
            <ToolTipsSubAccount
              isNotScroll
              isWhiteBackground
              content={content}
            />
          )}
        </label>
        <span>
          { displayPrice }
        </span>
      </div>
    )
  },
  renderSubItemCustomReimbursement(booking, countryLanguage, showTbd, isStep3 = false) {
    const customReimbursementsInfo = [...booking.custom_reimbursements_info]

    customReimbursementsInfo.sort((a, b) => (a.position - b.position))
    const result = customReimbursementsInfo.map((reimbursement) => {
      const { fees, hide_from_price_summary: hideFromPriceSummary } = reimbursement
      let displayPrice = Utils.formatFee(fees, countryLanguage)

      if (hideFromPriceSummary && isStep3) {
        return null
      }

      if (fees === 0) {
        if (!showTbd) {
          return null
        }
        displayPrice = I18n.t('webapp.pricing_display.tbd')
      }

      return (
        <div className="block-sub-item" key={`sub-custom-reim-${reimbursement.reimbursement_name}`}>
          <label>
            <span>
              {reimbursement.reimbursement_name}
            </span>
          </label>
          <span>
            { displayPrice }
          </span>
        </div>
      )
    })
    return result
  },
  renderSubItemTollsReimbursement(booking, countryLanguage) {
    const forceTBD = booking.show_tbd ? I18n.t('webapp.pricing_display.tbd') : undefined

    return (
      <div className="block-sub-item">
        <label>
          <span>
            {I18n.t('webapp.pricing_display.tolls')}
          </span>
        </label>
        <span>
          {Utils.formatFee(booking.tolls_fees, countryLanguage, undefined, forceTBD)}
        </span>
      </div>
    )
  },
  renderSubItemParkingReimbursement(booking, countryLanguage) {
    const forceTBD = booking.show_tbd ? I18n.t('webapp.pricing_display.tbd') : undefined
    return (
      <div className="block-sub-item">
        <label>
          <span>
            {I18n.t('webapp.pricing_display.parking')}
          </span>
        </label>
        <span>
          {Utils.formatFee(booking.parking_fees, countryLanguage, undefined, forceTBD)}
        </span>
      </div>
    )
  },
  renderSubItemWaitingTimeReimbursement(booking, countryLanguage) {
    const forceTBD = booking.show_tbd ? I18n.t('webapp.pricing_display.tbd') : undefined
    return (
      <div className="block-sub-item">
        <label>
          <span>
            {I18n.t('webapp.pricing_display.waiting_time')}
          </span>
        </label>
        <span>
          {Utils.formatFee(booking.waiting_time_fees, countryLanguage, undefined, forceTBD)}
        </span>
      </div>
    )
  },
  selectedExtraRequirementsPerLocation(extraServicePerLocations) {
    return _.find(extraServicePerLocations, extra => extra.selected_amount > 0)
  },
  selectedPOD(locations) {
    return _.find(locations, loc => loc.need_pod)
  },
  surchargeOldVersion(booking) {
    return booking.after_hour_service_fees > 0
  },
  renderLongHaulDropoff(details, countryLanguage) {
    return (
      <div className="Pricing-LongHaul-Show">
        {details.map(detail => (
          <div className="Pricing-LongHaul-List Pricing-LongHaul-Dropoff" key={detail.to}>
            <div>
              <div />
            </div>
            <div>
              <span>
                {detail.to}
              </span>
            </div>
            <div>
              <span>
                {Utils.countryLanguage(detail.fee, countryLanguage)}
              </span>
            </div>
          </div>
        ))}
      </div>
    )
  },
  renderStandardFee(booking, countryLanguage, vehicleType) {
    return (
      <div className="block-item">
        <label>
          <b>
            {renderFee(booking, countryLanguage, vehicleType)}
          </b>
        </label>
        {booking.booking_type === CUSTOM_PRICE && <img src={ICON_NOTE_CUSTOM_PRICE} alt="icon star" /> }
        <span>
          {booking.booking_type === CUSTOM_PRICE ? Utils.formatFee((booking.subtotal), countryLanguage)
            : Utils.formatFee((booking.distance_fees + booking.way_point_fees), countryLanguage)}
        </span>
      </div>
    )
  },
  renderSectionExtraService(booking, locations, countryLanguage) {
    if (_.isUndefined(booking.booking_extra_requirements)) {
      _.assign(booking, { booking_extra_requirements: [] })
    }
    const extraServicePerLocations = ReceiptUtils.getExtraServicePerLocations(booking, locations)
    const longHaulShowDocument = booking.time_type === LONG_HAUL
      && booking.long_haul_enable_pod
      && ReceiptUtils.selectedPOD(locations)

    const isCodPod =  booking.booking_type === CUSTOM_PRICE && booking.cod_pod
    const isExtraPerLocationOrPOD = booking.time_type === LONG_HAUL && (ReceiptUtils.selectedExtraRequirementsPerLocation(extraServicePerLocations) || ReceiptUtils.selectedPOD(locations))

    return (!_.isEmpty(booking.booking_extra_requirements)
      || _.toInteger(booking.out_of_service_area_fee) > 0
      || _.toInteger(booking.cod_pod_fees) > 0
      || _.toInteger(booking.after_hour_service_fees) > 0
      || _.toFinite(booking.surcharges_fees) !== 0
      || isCodPod
      || isExtraPerLocationOrPOD) && (
      <div>
        <div className="block-item block-item-custom">
          <label>
            <b>
              {I18n.t('webapp.pricing_display.extra_services')}
            </b>
          </label>
        </div>
        {
          booking.booking_extra_requirements.map(
            (extra, index) => this.renderExtraService(booking, extra, index, countryLanguage)
          )
        }
        {this.renderExtraServicePerLocations(extraServicePerLocations, countryLanguage)}
        {ReceiptUtils.renderSubItem(
          I18n.t('webapp.pricing_display.out_of_service_area_fee'),
          booking.out_of_service_area_fee,
          countryLanguage,
          'out_of_service_area_fee'
        )}
        {ReceiptUtils.selectedPOD(locations) && (
          ReceiptUtils.renderSubItem(
            booking.new_gen_pod || longHaulShowDocument ? I18n.t('webapp.new_booking.step_2.document_return') : I18n.t('webapp.pricing_display.cod_flash_pod'),
            longHaulShowDocument ? 0 : booking.cod_pod_fees,
            countryLanguage,
            booking.new_gen_pod || longHaulShowDocument ? '' : 'cod_pod_fees',
          )
        )}
        {booking.cod_pod && booking.booking_type === CUSTOM_PRICE && ReceiptUtils.renderSubItem(
          I18n.t('webapp.label.cod_flash_pod'),
          0,
          countryLanguage,
          'cod_pod',
          'customized_total_price'
        )}
        {ReceiptUtils.renderSubItem(
          ReceiptUtils.surchargeOldVersion(booking) ? `${booking.surcharge_pricing_title} (${booking.surcharge_pricing_percent}%)` : I18n.t('webapp.pricing_display.surcharges_fees'),
          ReceiptUtils.surchargeOldVersion(booking) ? booking.after_hour_service_fees : booking.surcharges_fees,
          countryLanguage,
          ReceiptUtils.surchargeOldVersion(booking) ? 'after_hour_service_fees' : 'surcharges_fees', '', booking.demand_adjustment_dynamic_text
        )}
      </div>
    )
  },
  renderExtraService(booking, extraRequirement, index, countryLanguage) {
    let totalPrice = extraRequirement.unit_price
    let displayName = extraRequirement.name
    let displayFee
    const dynamicTextPlatformFee = store.getState().extraInfos?.dynamic_texts?.platform_fee
    const isByOptions = extraRequirement.pricing_method === EXTRA_REQUIREMENT_VEHICLE_TYPE_BY_OPTIONS
    if (isByOptions) {
      if (!_.isUndefined(extraRequirement.selectedPricing)) {
        totalPrice = extraRequirement.selectedPricing.fees
        displayFee = extraRequirement.selectedPricing.display_fees_without_currency
        displayName = `${extraRequirement.name_on_pricing_list} ${extraRequirement.selectedPricing.display_level_price}`
      } else {
        totalPrice = extraRequirement.unit_price
        displayFee = extraRequirement.display_fees_without_currency
        displayName = `${extraRequirement.name_on_pricing_list} ${extraRequirement.display_level_price}`
      }
    } else if (booking.time_type === FULL_DAY) {
      if (!extraRequirement.is_flat) {
        // if not full-day - flat price
        totalPrice *= extraRequirement.selected_amount * booking.full_day_selected_amount
        if (extraRequirement.selected_amount > 1) {
          displayName = `${displayName} x${extraRequirement.selected_amount}`
        }
      }
    } else if (booking.time_type === LONG_HAUL) {
      if (extraRequirement.is_flat_per_location === true) {
        return (null)
      }
      if (extraRequirement.is_per_unit_fee) {
        // LongHaul - Per Unit Fee
        totalPrice *= extraRequirement.selected_amount
        if (extraRequirement.selected_amount > 1) {
          displayName = `${displayName} x${extraRequirement.selected_amount}`
        }
      } else if (!extraRequirement.is_flat) {
        // if not long-haul - flat price
        totalPrice *= extraRequirement.selected_amount * booking.distance_fee_details.total_working_days
        if (extraRequirement.selected_amount > 1) {
          displayName = `${displayName} x${extraRequirement.selected_amount}`
        }
      }
    } else {
      totalPrice *= extraRequirement.selected_amount
      if (extraRequirement.selected_amount > 1) {
        displayName = `${displayName} x${extraRequirement.selected_amount}`
      }
    }
    const isShowTooltip = checkIsShowTooltipPlatformFee(extraRequirement, dynamicTextPlatformFee) && !isByOptions
    let zIndex = 1
    if (isShowTooltip) {
      zIndex = 100 - index
      if (extraRequirement.position < 0) {
        zIndex = 200 - index
      }
    }
    return (
      <div className="block-sub-item" key={index} style={{ zIndex }}>
        <label className='flex'>
          <span>
            {displayName}
          </span>
          {isShowTooltip && <HelpTooltip dynamicText={dynamicTextPlatformFee}/> }
        </label>
        <span>
          {booking.booking_type === CUSTOM_PRICE ? I18n.t('webapp.pricing_display.included') : displayFee || Utils.formatFee(totalPrice, countryLanguage)}
        </span>
      </div>
    )
  },
  getExtraServicePerLocations(booking, locations) {
    const extraServicePerLocations = []
    if (locations && booking.time_type === LONG_HAUL) {
      _.forEach(locations, (location) => {
        _.forEach(location.extra_requirement_locations, (extraRequirementLocation) => {
          const extraRequirementId = extraRequirementLocation.extra_requirement_id
          const extraRequirement = _.find(extraServicePerLocations, { extra_requirement_id: extraRequirementId })
          if (_.isEmpty(extraRequirement)) {
            const totalExtraRequirementLocation = {
              extra_requirement_id: extraRequirementId,
              display_name: extraRequirementLocation.name,
              unit_price: extraRequirementLocation.unit_price,
              selected_amount: _.toInteger(extraRequirementLocation.selected_amount)
            }
            extraServicePerLocations.push(totalExtraRequirementLocation)
          } else {
            extraRequirement.selected_amount += _.toInteger(extraRequirementLocation.selected_amount)
          }
        })
      })
    }
    return extraServicePerLocations
  },
  renderExtraServicePerLocations(extraServicePerLocations, countryLanguage) {
    return (
      <div>
        {extraServicePerLocations.map((extraRequirement) => {
          const totalPrice = extraRequirement.selected_amount * extraRequirement.unit_price
          if (totalPrice <= 0) {
            return (null)
          }
          let displayName = extraRequirement.display_name
          if (extraRequirement.selected_amount > 1) {
            displayName = `${displayName} x${extraRequirement.selected_amount}`
          }
          return (
            <div className="block-sub-item" key={extraRequirement.extra_requirement_id}>
              <label>
                <span>
                  {displayName}
                </span>
              </label>
              <span>
                {Utils.formatFee(totalPrice, countryLanguage)}
              </span>
            </div>
          )
        })}
      </div>
    )
  },
  renderSectionBadge(bookingBadges, countryLanguage, bookingType) {
    return !_.isEmpty(bookingBadges) && (
      <div>
        <div className="block-item block-item-custom">
          <label>
            <b>
              {I18n.t('webapp.pricing_display.badges')}
            </b>
          </label>
        </div>
        {bookingBadges
          .map((badge, index) => ReceiptUtils.renderSubItem(badge.name, 0, countryLanguage, index, bookingType))}
      </div>
    )
  },
  renderOldLongHaulStandardFee(routes, collapse, handleClick, countryLanguage) {
    return (
      <div className="Pricing-LongHaul">
        <div className="block-item block-item-custom">
          <label>
            <b>
              {I18n.t('webapp.pricing_display.standard_fare')}
            </b>
          </label>
        </div>
        <div className="block-item Pricing-LongHaul-Group">
          <div className="Pricing-LongHaul-List Pricing-LongHaul-From">
            <div>
              <div className="blue Pin">
                {I18n.t('closures.label.fr')}
              </div>
            </div>
            <div>
              <span>
                {routes[0].from}
              </span>
            </div>
            <div>
              <button
                type="button"
                className="icon-only Button Pricing-LongHaul-Button"
                onClick={() => handleClick()}
              >
                {collapse
                  ? (
                    <i className="b material-icons Icon">
                      keyboard_arrow_up
                    </i>
                  ) : (
                    <i className="b material-icons Icon Collapse-Icon">
                      keyboard_arrow_down
                    </i>
                  )
                }
              </button>
            </div>
          </div>
          {
            routes.map(
              (dropoff, index) => ReceiptUtils.renderLongHaulDropoffArea(
                dropoff, index, collapse, countryLanguage
              )
            )
          }
        </div>
      </div>
    )
  },
  renderNewLongHaulStandardFee(routes, freeInsideZoneStops, countryLanguage) {
    const isShowPinFromTo = _.size(routes) === 2 && (_.findIndex(routes, route => route.details.length > 1) === -1)
    let dropoffIndex = 0
    return (
      <div className="Pricing-LongHaul New-Pricing-LongHaul">
        {routes.map((zone) => {
          const zonePriceIcon = zone.type === 'zone' ? FIXED_PRICE_ICON_LONG_HAUL : PRICE_OUTSIDE_ICON_LONG_HAUL
          return (
            <div key={`${zone.name}-${dropoffIndex}`} className="relative">
              <div className="block-item-sub">
                <div className="block-item block-item-custom">
                  <label>
                    <b>
                      <img src={zonePriceIcon} alt="fixed-price-icon" className="fixed-price-icon" />
                      {zone.name === '' ? I18n.t('webapp.pricing_display.outside_stops') : zone.name}
                    </b>
                  </label>
                  {
                    dropoffIndex !== 0
                      ? (
                        <span>
                          { zone.fee && Utils.formatFee(zone.fee, countryLanguage) }
                        </span>
                      )
                      : (
                        <span className="start-text">
                          { I18n.t('webapp.pricing_display.start') }
                        </span>
                      )
                  }
                </div>
              </div>
              <div className="block-item Pricing-LongHaul-Group">
                {zone.details.map((dropoff) => {
                  const dIndex = dropoffIndex
                  dropoffIndex += 1
                  return (
                    <div className="Pricing-LongHaul-List Pricing-LongHaul-From pt10 pb10" key={dropoffIndex}>
                      <div>
                        <div className="prefix-dot-column" />
                        <div className="prefix-dot-row" />
                        {dIndex === 0
                          ? (
                            <div className="blue Pin">
                              {I18n.t('closures.label.fr')}
                            </div>
                          ) : (
                            <div className="yellow Pin">
                              {isShowPinFromTo ? I18n.t('closures.label.to') : dIndex}
                            </div>
                          )
                        }
                      </div>
                      <div>
                        <span>
                          {zone.formatted_address || dropoff}
                        </span>
                      </div>
                      {freeInsideZoneStops && dIndex !== 0
                        && (zone.type === 'zone' || (zone.long_haul_area_id !== undefined && zone.long_haul_area_id !== null))
                        && (
                          <div>
                            <img src={FREE_INSIDE_ICON_LONG_HAUL} alt="free-inside-icon" />
                            <span>
                              0
                            </span>
                          </div>
                        )
                      }
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    )
  },
  renderLongHaulStandardFee(booking, collapse, handleClick, countryLanguage) {
    if (!booking.distance_fee_details) {
      return (null)
    }
    const routes = booking.distance_fee_details.routes
    const freeInsideZoneStops = booking.distance_fee_details.free_inside_zone_stops
    if (_.isUndefined(freeInsideZoneStops)) {
      return ReceiptUtils.renderOldLongHaulStandardFee(routes, collapse, handleClick, countryLanguage)
    }
    return ReceiptUtils.renderNewLongHaulStandardFee(routes, freeInsideZoneStops, countryLanguage)
  },
  renderLongHaulDropoffArea(dropoff, index, collapse, countryLanguage) {
    return (
      <div className="w100" key={index}>
        {collapse && ReceiptUtils.renderLongHaulDropoff(dropoff.details, countryLanguage)}
        <div className={`Pricing-LongHaul-List Pricing-LongHaul-To ${collapse ? 'Expand' : 'Collapse'}`}>
          <div>
            <div className="yellow Pin">
              {I18n.t('closures.label.to')}
            </div>
          </div>
          <div>
            <span>
              {dropoff.to}
            </span>
          </div>
          <div>
            <span>
              {
                Utils.formatFee(dropoff.fee, countryLanguage)
              }
            </span>
          </div>
        </div>
      </div>
    )
  },

  allowShowBookingTollsFee(booking) {
    return (
      booking.allow_tolls_fees
        && (booking.tolls_fees > 0 || (booking.tolls_fees === 0 && booking.show_tbd))
    )
  },
  allowShowBookingParkingFee(booking) {
    return (
      booking.allow_parking_fees
        && (booking.parking_fees > 0 || (booking.parking_fees === 0 && booking.show_tbd))
    )
  },
  allowShowBookingWaitingTimeFee(booking) {
    return (
      booking.allow_waiting_time_fees
        && (booking.waiting_time_fees > 0 || (booking.waiting_time_fees === 0 && booking.show_tbd))
    )
  },
  allowShowBookingCustomReimbursement(booking, showTbd, isStep3 = false) {
    const customReimbursementsInfo = booking.custom_reimbursements_info
    if (customReimbursementsInfo && customReimbursementsInfo.length > 0) {
      for (const reimbursement of customReimbursementsInfo) {
        const { fees, hide_from_price_summary: hideFromPriceSummary } = reimbursement
        const isHide = hideFromPriceSummary && isStep3

        if ((fees > 0 || (fees === 0 && showTbd)) && !isHide) {
          return true
        }
      }
    }
    return false
  },
  renderSectionReimbursements(booking, countryLanguage) {
    const {
      free_reimbursement_max_cap: freeReimbursementsMaxCap,
      free_reimbursements: freeReimbursements
    } = booking
    const showFreeReimbursements = !!(freeReimbursements && freeReimbursements !== 0 && freeReimbursementsMaxCap > 0)
    const freeMaxCap = Utils.formatFee(freeReimbursementsMaxCap, countryLanguage)
    return (
      <div>
        <div className="block-item block-item-custom">
          <label>
            <b>
              {I18n.t('webapp.pricing_display.reimbursements_to_driver')}
            </b>
          </label>
        </div>
        {ReceiptUtils.allowShowBookingTollsFee(booking)
          && ReceiptUtils.renderSubItemTollsReimbursement(booking, countryLanguage)
        }
        {ReceiptUtils.allowShowBookingParkingFee(booking)
          && ReceiptUtils.renderSubItemParkingReimbursement(booking, countryLanguage)
        }
        {ReceiptUtils.allowShowBookingWaitingTimeFee(booking)
          && ReceiptUtils.renderSubItemWaitingTimeReimbursement(booking, countryLanguage)
        }
        {ReceiptUtils.allowShowBookingCustomReimbursement(booking, booking.show_tbd)
          && ReceiptUtils.renderSubItemCustomReimbursement(booking, countryLanguage, booking.show_tbd)
        }
        {showFreeReimbursements && (
          <div className="block-sub-item">
            <label>
              <span>
                {I18n.t('webapp.pricing_display.free_reimbursements_cap_fullday', { value: freeMaxCap })}
              </span>
            </label>
            <span>
              { Utils.formatFee(booking.free_reimbursements, countryLanguage) }
            </span>
          </div>
        )}
      </div>
    )
  },
  renderSubTotal(booking, countryLanguage) {
    if (booking.discount_amount
      || booking.credit_amount
      || booking.company_type_discount
      || booking.business_credit_amount
      || booking.round_trip_discount_amount
      || _.toFinite(booking.surcharges_adjustments) !== 0
      || booking.custom_reimbursements_info?.length) {
      return (
        <div className="block-item block-item-custom subtotal">
          <label>
            <b>
              {I18n.t('webapp.pricing_display.subtotal')}
            </b>
          </label>
          <span>
            {Utils.formatFee(booking.subtotal, countryLanguage)}
          </span>
        </div>
      )
    }
    return null
  },
  renderRoundTripDiscount(booking, countryLanguage) {
    const roundTripDiscountLabel = I18n.t('webapp.pricing_display.round_trip_discount')
    const showRoundTripDiscount = booking.round_trip_discount && booking.round_trip_discount_amount > 0
    if (showRoundTripDiscount) {
      return (
        <div className="block-item block-item-sub">
          {ReceiptUtils.renderDiscount(roundTripDiscountLabel, booking.round_trip_discount_amount, countryLanguage, 'discount_round_trip_amount')}
        </div>
      )
    }
    return <span />
  },
  renderDiscountSection(booking, discountCode, countryLanguage) {
    if (booking.discount_amount >= 0 || booking.company_type_discount || booking.business_credit_amount) {
      const discountLabel = discountCode?.isDiscountFirstTime ? booking.first_time_discount_message : I18n.t('webapp.pricing_display.discount_amount')
      const showDiscount = booking.discount_id && booking.discount_amount >= 0
      if (showDiscount) {
        return (
          <div className="block-item block-item-sub">
            {ReceiptUtils.renderDiscount(discountLabel, booking.discount_amount, countryLanguage, 'discount_amount')}
          </div>
        )
      }
    }
    return null
  },
  renderDiscount(label, price, countryLanguage, key = undefined) {
    return (
      <div className="block-item block-item-custom" key={key}>
        <label>
          <b>
            {label}
          </b>
        </label>
        <span>
          {price !== 0 ? `-${Utils.formatFee(price, countryLanguage)}` : 0}
        </span>
      </div>
    )
  },
  renderCreditSection(booking, countryLanguage) {
    if (booking.credit_amount || booking.company_type_discount || booking.business_credit_amount) {
      const creditAmount = booking.cashback_credit_used || booking.credit_amount

      if (creditAmount > 0) {
        return (
          <div className="block-item block-item-sub">
            {ReceiptUtils.renderSubItem(I18n.t('webapp.pricing_display.credit_amount'), creditAmount, countryLanguage, 'credit_amount')}
          </div>
        )
      }
    }
    return null
  },
  renderTipForVendor(booking, countryLanguage) {
    const vendorTippingAmount = booking?.vendor_tipping_amount
    return vendorTippingAmount && (
      <div className="block-item block-item-custom z-index-2-max">
        <label>
          <b>
            {I18n.t('webapp.pricing_display.tip_for_vendor')}
          </b>
        </label>
        <span>
          {`${Utils.formatFee(vendorTippingAmount, countryLanguage)}`}
        </span>
      </div>
    )
  },
  renderTipPaymentServiceFee(booking, countryLanguage) {
    const tipPaymentServiceFee = booking?.tip_payment_service_fee
    return tipPaymentServiceFee && (
      <div className="dlvr-price-item z-index-4">
        <label className="flex">
          <span>
            {I18n.t('webapp.pricing_display.tip_payment_service_fee')}
          </span>
        </label>
        <span>
          {`${Utils.formatFee(tipPaymentServiceFee, countryLanguage)}`}
        </span>
      </div>
    )
  },
  renderBusinessSection(booking, countryLanguage) {
    if (booking.discount_amount || booking.credit_amount
      || booking.company_type_discount || booking.business_credit_amount) {
      return (_.toInteger(booking.company_type_discount) > 0 || _.toInteger(booking.business_credit_amount) > 0) && (
        <div>
          <div className="block-item block-item-custom">
            <label>
              <b>
                {I18n.t('webapp.pricing_display.business_block_title')}
              </b>
            </label>
          </div>
          {ReceiptUtils.renderSubItem(I18n.t('webapp.pricing_display.company_type_discount'), booking.company_type_discount, countryLanguage, 'company_type_discount')}
          {ReceiptUtils.renderSubItem(I18n.t('webapp.pricing_display.business_credit'), booking.business_credit_amount, countryLanguage, 'business_credit_amount')}
        </div>
      )
    }
    return null
  },
  renderSurchargeAdjustment(booking, countryLanguage, isPopupPrice = false, isBatchEzsheet = false) {
    const surchargesAdjustments = booking.surcharges_adjustments
    const content = !_.isEmpty(booking.special_adjustment_dynamic_text) && booking.special_adjustment_dynamic_text.text
    && renderHyperLink(booking.special_adjustment_dynamic_text.hyperlink_url,
      booking.special_adjustment_dynamic_text.text, booking.special_adjustment_dynamic_text.hyperlink_text, 'hyperlink-color-tooltip')
    return (_.toFinite(surchargesAdjustments) !== 0) && (
      <div className="block-item block-item-custom z-index-4">
        <label className="flex">
          <b className="Green-text-important">
            {I18n.t('webapp.pricing_display.surcharges_adjustments')}
          </b>
          {booking.special_adjustment_dynamic_text?.text
            && (
              <ToolTipsSubAccount
                isNotScroll
                isPopupPrice={isPopupPrice}
                isWhiteBackground
                isBatchEzsheet={isBatchEzsheet}
                content={content}
              />
            )
          }
        </label>
        <span className="Green-text-important">
          {surchargesAdjustments < 0
            ? Utils.formatFee(Math.abs(surchargesAdjustments), countryLanguage)
            : `-${Utils.formatFee(surchargesAdjustments, countryLanguage)}`}
        </span>
      </div>
    )
  },
  renderTopUpToWallet(booking, countryLanguage, minimumAmount = 0, isPopupPrice = false, isBatchEzsheet = false) {
    const topUpToWalletFee = booking?.top_up_to_wallet
    const number = Utils.formatFee(minimumAmount, countryLanguage, booking.currency)
    const minAmountValueText = booking?.bankTransfer?.translations?.minAmountValueText?.replace(
      `${booking?.bankTransfer?.translations.replaceKey}`, number
    )
    const content = renderHyperLink('', minAmountValueText, '', 'hyperlink-color-tooltip')
    return (_.toFinite(topUpToWalletFee) !== 0 && !_.isEmpty(booking?.bankTransfer)) && (
      <div className="block-item block-item-custom z-index-3-max">
        <label className="flex">
          <b>
            {booking?.bankTransfer?.translations?.topUp}
          </b>
          {minimumAmount > 0
            && (
              <ToolTipsSubAccount
                isNotScroll
                isPopupPrice={isPopupPrice}
                isWhiteBackground
                isBatchEzsheet={isBatchEzsheet}
                content={content}
              />
            )
          }
        </label>
        <span>
          {Utils.formatFee(topUpToWalletFee, countryLanguage)}
        </span>
      </div>
    )
  },
  renderOnlinePaymentService(booking, countryLanguage) {
    const onlinePaymentFee = booking?.payment_service_fee
    const text = booking?.bankTransfer?.translations?.paymentServiceText
    const finalText = booking?.payment_service_count > 1
      ? `${text} (x${booking?.payment_service_count})`
      : `${text}`
    return (_.toFinite(onlinePaymentFee) !== 0 && !_.isEmpty(booking?.bankTransfer)) && (
      <div className="block-item block-item-custom z-index-2-max">
        <label>
          <b>
            {finalText}
          </b>
        </label>
        <span>
          {Utils.formatFee(onlinePaymentFee, countryLanguage)}
        </span>
      </div>
    )
  },
  renderPaidAmountPayment(booking, countryLanguage) {
    const paidAmountFee = booking?.paid_amount
    const paidBy = booking?.bankTransfer?.translations?.paidBy
    return (_.toFinite(paidAmountFee) !== 0 && !_.isEmpty(booking?.bankTransfer)) && (
      <div className="block-item block-item-custom z-index-2-max">
        <label>
          <b>
            {paidBy}
          </b>
        </label>
        <span>
          {`-${Utils.formatFee(paidAmountFee, countryLanguage)}`}
        </span>
      </div>
    )
  },
  renderRefundToDelivereeWallet(booking, countryLanguage) {
    const refundToWallet = booking?.refund_to_wallet
    const refundTo = booking?.bankTransfer?.translations?.refundTo
    return (_.toFinite(refundToWallet) !== 0 && !_.isEmpty(booking?.bankTransfer)) && (
      <div className="block-item block-item-custom z-index-2-max">
        <label>
          <b>
            {refundTo}
          </b>
        </label>
        <span>
          {Utils.formatFee(refundToWallet, countryLanguage)}
        </span>
      </div>
    )
  },
  renderTotal(booking, countryLanguage) {
    return (
      <div className="block-item total relative">
        <label>
          <span className="White-bg pr5">
            {booking.is_post_payment ? I18n.t('webapp.pricing_display.invoice') : I18n.t('webapp.pricing_display.total')}
          </span>
        </label>
        <span className="White-bg">
          {Utils.formatFee(booking.total_fees, countryLanguage, booking.currency)}
        </span>
      </div>
    )
  },
  renderNotes(booking, isShowDynamicText = true) {
    const companyID = booking.company_id || 0
    let title = ''
    if (companyID !== 0) title = I18n.t('webapp.pricing_display.note_for_business_title')
    else title = I18n.t(`webapp.pricing_display.note_for_personal_${booking.payment_method_for_non_bp}_title`)
    const note = booking.footnote_for_booking
    let freeInsideZoneStops
    let priceOutsideZone
    if (booking.time_type === LONG_HAUL) {
      const distanceFeeDetails = booking.distance_fee_details
      if (!_.isEmpty(distanceFeeDetails)) {
        freeInsideZoneStops = distanceFeeDetails.free_inside_zone_stops
        priceOutsideZone = !_.isEmpty(distanceFeeDetails.routes.filter(i => i.type === 'outside'))
      }
    }
    return (
      <div>
        <div className="Pricing noted Pricing-bock-custom relative">
          <div className="block-item noted block-item-custom">
            <b className="uppercase green" dangerouslySetInnerHTML={{ __html: title }} />
          </div>
          <span className="Pricing-bock-custom__line" />
        </div>
        {!_.isUndefined(freeInsideZoneStops) && ReceiptUtils.renderNotesLongHaul(freeInsideZoneStops, priceOutsideZone)}
        {isShowDynamicText && (
          <div className="PricingNote block reset pt">
            <div className="block-item">
              <p className="reset m small-text default-color aligned center pt5" dangerouslySetInnerHTML={{ __html: note }} />
            </div>
          </div>
        )}
      </div>
    )
  },
  renderNotesStep3(booking) {
    const companyID = booking.company_id || 0
    let title = ''
    if (companyID !== 0) title = I18n.t('webapp.pricing_display.note_for_business_title')
    else title = I18n.t(`webapp.pricing_display.note_for_personal_${booking.payment_method_for_non_bp}_title`)
    let freeInsideZoneStops
    let priceOutsideZone
    if (booking.time_type === LONG_HAUL) {
      const distanceFeeDetails = booking.distance_fee_details
      if (!_.isEmpty(distanceFeeDetails)) {
        freeInsideZoneStops = distanceFeeDetails.free_inside_zone_stops
        priceOutsideZone = !_.isEmpty(distanceFeeDetails.routes.filter(i => i.type === 'outside'))
      }
    }
    return (
      <div>
        <div className="Pricing noted Pricing-bock-custom relative">
          <div className="block-item noted block-item-custom">
            <b className="uppercase green" dangerouslySetInnerHTML={{ __html: title }} />
          </div>
          <span className="Pricing-bock-custom__line" />
        </div>
        {!_.isUndefined(freeInsideZoneStops) && ReceiptUtils.renderNotesLongHaul(freeInsideZoneStops, priceOutsideZone)}
      </div>
    )
  },
  renderNotesLongHaul(freeInsideZoneStops, priceOutsideZone) {
    return (
      <div className="PricingNote PricingNote-LongHaul block">
        <div>
          <img src={FIXED_PRICE_ICON_LONG_HAUL} alt="fixed-price-icon" />
          <span>
            {I18n.t('webapp.pricing_display.fixed_price_zone_to_zone')}
          </span>
        </div>
        {freeInsideZoneStops
          && (
            <div>
              <img src={FREE_INSIDE_ICON_LONG_HAUL} alt="free-inside-icon" />
              <span>
                {I18n.t('webapp.pricing_display.free_inside_zone')}
              </span>
            </div>
          )
        }
        { priceOutsideZone
          && (
            <div>
              <img src={PRICE_OUTSIDE_ICON_LONG_HAUL} alt="price-outside-icon" />
              <span>
                {I18n.t('webapp.pricing_display.price_outside_zone')}
              </span>
            </div>
          )
        }
      </div>
    )
  },
  renderSettlementTransactions(settlement, countryLanguage) {
    const transactions = settlement?.transactions || []
    const rejectedClass = settlement.status === SETTLEMENT_REJECTED && 'line-through-red'
    const reason = (transactions.length === 1 && transactions[0]?.reason) ? transactions[0].reason : I18n.t('webapp.pricing_display.multi_event')
    return (
      <div className="block-sub-item">
        <label>
          <span className="default-color">
            {reason}
          </span>
        </label>
        <span className={`default-color ${rejectedClass}`}>
          {Utils.formatFee(settlement.amount, countryLanguage)}
        </span>
      </div>
    )
  },
  renderSettlements(booking, countryLanguage, isRenderPopupEye) {
    const settlement = booking.settlement_details

    if (!settlement) { return null }

    const info = {
      icon: ICON_CANCEL_FEE,
      title: I18n.t('popup.settlements.title'),
      subTitle: '',
    }

    const content = () => (
      <p className="center default-font reset m p">
        {I18n.t('popup.settlements.content', { app_name: commonUtils.appName() })}
      </p>
    )

    const renderEyeIcon = () => (
      <i className="b material-icons Icon">
        visibility
      </i>
    )

    return (
      <div className="cancel-fee-bg driver-cancel-handler">
        <div className="block-item block-item-custom">
          <label className="flex flex-center-algin">
            <b className="mr5">
              {I18n.t('webapp.pricing_display.settlements')}
            </b>
            {isRenderPopupEye
              ? (
                <DynamicPopupHandler
                  specialClass="Vehicle-Service-Type-Popup-Eye"
                  renderChild={renderEyeIcon}
                  renderInfo={info}
                  renderDynamicContent={content}
                  renderNode={document.getElementById('DimensionPopupDefault')}
                  correctHeight
                />
              )
              : (
                <div className="driver-cancel-handler-icon relative cur-pointer">
                  <i className="b material-icons Icon default-color-icon">
                    visibility
                  </i>
                  <div className="driver-cancel-tooltip-arrow Dark-Green-bg" />
                </div>
              )
            }
          </label>
        </div>
        {!isRenderPopupEye && (
          <div className="driver-cancel-tooltip Dark-Green-bg Radius-default mar10">
            <div className="driver-cancel-tooltip-arrow" />
            <div className="pt10 pr10 pb10 pl10">
              <p className="reset m p White-text Left-text">
                {I18n.t('popup.settlements.content', { app_name: commonUtils.appName() })}
              </p>
            </div>
          </div>
        )}
        {ReceiptUtils.renderSettlementTransactions(settlement, countryLanguage)}
      </div>
    )
  },
  renderShoppingItemList(shoppingItems, removeClass = false) {
    return (
      <div className={`block-item ${removeClass ? '' : 'block-item-custom'}`}>
        <label>
          <b>
            {I18n.t('webapp.shopping.list')}
          </b>
        </label>
        <span>
          {I18n.t('webapp.shopping.list_items', { items: _.size(shoppingItems) })}
        </span>
      </div>
    )
  },
  renderShoppingPriceDriver(total = 0, booking, title = '') {
    return (
      <div className="block-item block-item-custom">
        <label>
          <b>
            {title}
          </b>
        </label>
        <span>
          {Utils.formatFee(total, booking.area_language, undefined, undefined, 2)}
        </span>
      </div>
    )
  },
  renderShoppingPriceNote() {
    return (
      <div className="block-item block-item-custom">
        <label>
          <b>
            {I18n.t('webapp.shopping.price')}
          </b>
        </label>
        <span>
          {I18n.t('webapp.shopping.actual_store_price')}
        </span>
      </div>
    )
  },
  renderShoppingPrice(booking, countryLanguage, title = '', renderCurrency = true, withDistance = false) {
    if (booking.discount_amount
      || booking.credit_amount
      || booking.company_type_discount
      || booking.business_credit_amount
      || booking.round_trip_discount_amount
      || _.toFinite(booking.surcharges_adjustments) !== 0) {
      let servicePriceAndDistance = renderCurrency
        ? Utils.formatFee(booking.subtotal, countryLanguage, booking.currency)
        : Utils.formatFee(booking.subtotal, countryLanguage)
      if (withDistance) {
        servicePriceAndDistance += ` ${I18n.t('webapp.shopping.for_distance', { value: booking.total_distance })} ${I18n.t('webapp.booking.km')}`
      }
      return (
        <div className="block-item block-item-custom subtotal">
          <label>
            <b>
              {title || I18n.t('webapp.label.service')}
            </b>
          </label>
          <span>
            {
              servicePriceAndDistance
            }
          </span>
        </div>
      )
    }
    return null
  },
  renderShoppingBag(shoppingBags = {}, booking, title = '') {
    if (!_.size(shoppingBags)) return null
    const { shoppingBag, shoppingBagQuantity } = shoppingBags
    return (
      <div className="block-item block-item-custom">
        <label>
          <b>
            {title}
          </b>
        </label>
        <span>
          {Utils.formatFee(shoppingBag * shoppingBagQuantity, booking.area_language)}
        </span>
      </div>
    )
  },
  renderShoppingDiscount(discount, booking, title = '') {
    if (discount === 0) return null
    return (
      <div className="block-item block-item-custom">
        <label>
          <b>
            {title}
          </b>
        </label>
        <span>
          {`-${Utils.formatFee(discount, booking.area_language)}`}
        </span>
      </div>
    )
  },
  renderShoppingPriceTotal(total = 0, booking, title = '') {
    return (
      <div className="block-item block-item-custom subtotal">
        <label>
          <b>
            {title}
          </b>
        </label>
        <span>
          {Utils.formatFee(total + booking.subtotal, booking.area_language, booking.currency, undefined, 2)}
        </span>
      </div>
    )
  },
  renderFreeCapCustomReimbursement(booking, countryLanguage) {
    const {
      free_reimbursement_max_cap: freeReimbursementsMaxCap,
      free_reimbursements: freeReimbursements
    } = booking
    const showFreeReimbursements = !!(freeReimbursements && freeReimbursements !== 0 && freeReimbursementsMaxCap > 0)
    const freeMaxCap = Utils.formatFee(freeReimbursementsMaxCap, countryLanguage)
    return showFreeReimbursements && (
      <div className="block-sub-item" key={`max-cap-${booking.id}`}>
        <label>
          <span>
            {I18n.t('webapp.pricing_display.free_reimbursements_cap_fullday', { value: freeMaxCap })}
          </span>
        </label>
        <span>
          { Utils.formatFee(freeReimbursements, countryLanguage) }
        </span>
      </div>
    )
  },
  renderStaticAndCustomReimbursement(booking, countryLanguage) {
    // for step 3 then always show tbd
    return ReceiptUtils.allowShowBookingCustomReimbursement(booking, true, true) && (
      <div>
        <div className="block-item block-item-custom">
          <label>
            <b>
              {I18n.t('webapp.pricing_display.reimbursements_to_driver')}
            </b>
          </label>
        </div>
        {ReceiptUtils.renderSubItemCustomReimbursement(booking, countryLanguage, true, true)}
        {ReceiptUtils.renderFreeCapCustomReimbursement(booking, countryLanguage)}
      </div>
    )
  },
}

export default ReceiptUtils
